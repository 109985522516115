import '@aws-amplify/ui-react/styles.css';
import React, { useEffect, useState } from 'react';
import { Authenticator, ThemeProvider, useAuthenticator } from '@aws-amplify/ui-react';
import { getCurrentUser } from '@aws-amplify/auth';
import { useNavigate } from 'react-router-dom';

const customTheme = {
  name: 'my-theme',
  tokens: {
    colors: {
      brand: {
        primary: {
          value: '#ff9900' // Custom primary color
        }
      }
    },
    components: {
      authenticator: {
        button: {
          primary: {
            backgroundColor: { value: '#ff9900' }, // Use custom primary color
            borderRadius: { value: '0.5rem' }, // Rounded button corners
            fontSize: { value: '1rem' } // Font size for buttons
          }
        }
      }
    }
  }
};


function SignIn() {
  return (
    <ThemeProvider theme={customTheme}>
      <Authenticator hideSignUp>
        {({ signOut, user }) => (
          <AuthenticatedContent user={user} signOut={signOut} />
        )}
      </Authenticator>
    </ThemeProvider>
  );
}

function AuthenticatedContent({ user, signOut }) {
  const navigate = useNavigate();
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);

  useEffect(() => {
    if (authStatus === 'authenticated') {
      navigate('/add-contact');
    }
  }, [authStatus, navigate]);

  return user ? (
    <div>
      <h1>Welcome {user.username}!</h1>
      <button onClick={signOut}>Sign out</button>
    </div>
  ) : (
    <h1>Please sign in</h1>
  );
}

export default SignIn;
