import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SignIn from './components/SignIn';
import AddContact from './components/AddContact';
import ViewRecords from './components/ViewRecords';
import PrivateRoute from './components/PrivateRoute';
import HomePage from './components/HomePage';
// Import other components that you will route to

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/signin" element={<SignIn />} />
        <Route 
          path="/add-contact" 
          element={
            <PrivateRoute>
              <AddContact />
            </PrivateRoute>
          } 
        />
        <Route
          path="/view-records"
          element={
            <PrivateRoute>
              <ViewRecords />
            </PrivateRoute>
          } 
        />
      </Routes>
    </Router>
  );
}
export default App;
