import React from 'react';
import { useNavigate } from 'react-router-dom';
import './HomePage.css';

const HomePage = () => {
  const navigate = useNavigate();


  return (
    <div className="home-container">
      <img src={require("../assets/srs-primary-red.png")} alt="Logo" className="logo" />
      <h1>SRS ID Management Interface</h1>
      <button onClick={() => navigate('/signin')}>Sign In</button>
      </div>
  );
};

export default HomePage;
