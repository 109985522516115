import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './AddContact.css';
import { v4 as uuidv4 } from 'uuid'; 
import { fetchAuthSession } from 'aws-amplify/auth';
const AddContact = () => {
  const [contact, setContact] = useState({
    first_name: '',
    last_name: '',
    organisation: '',
    title: '',
    phone: '',
    email: '',
    address: '',
    url: ''
  });

  const navigate = useNavigate();

  const goToViewRecords = () => {
    navigate('/view-records');
  };

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');

  const handleChange = (e) => {
    setContact({ ...contact, [e.target.name]: e.target.value });
  };



const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    const id = uuidv4();

    try {
      const session = await fetchAuthSession();
      const accessToken = session.tokens.accessToken.toString();

      console.log('Bearer token:', accessToken);

      const response = await axios.post('https://api.srsnsw.com.au/contacts', { ...contact, id }, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      });

      console.log(response.data);
      if (response.status === 200 || response.status === 201) {
        // Resetting form and showing success message
        setContact({
          first_name: '',
          last_name: '',
          organisation: '',
          title: '',
          phone: '',
          email: '',
          address: '',
          url: ''
        });
        setSuccessMessage('Record added successfully');
        setTimeout(() => setSuccessMessage(''), 3000);
      }
    } catch (error) {
      console.error('Error adding contact', error);
    } finally {
      setIsSubmitting(false);
    }
};



  return (
    <form onSubmit={handleSubmit} className="form-container">
      <input type="text" name="first_name" value={contact.first_name} onChange={handleChange} placeholder="First Name" />
      <input type="text" name="last_name" value={contact.last_name} onChange={handleChange} placeholder="Last Name" />
      <input type="text" name="organisation" value={contact.organisation} onChange={handleChange} placeholder="Organisation" />
      <input type="text" name="title" value={contact.title} onChange={handleChange} placeholder="Title" />
      <input type="tel" name="phone" value={contact.phone} onChange={handleChange} placeholder="Phone" />
      <input type="email" name="email" value={contact.email} onChange={handleChange} placeholder="Email" />
      <input type="text" name="address" value={contact.address} onChange={handleChange} placeholder="Address" />
      <input type="url" name="url" value={contact.url} onChange={handleChange} placeholder="URL" />
      <div className="button-container">
        <button type="submit">Submit</button>
        <button type="button" onClick={goToViewRecords}>View Records</button>
      </div>
      {successMessage && <div>{successMessage}</div>}
    </form>
  );
};

export default AddContact;
