import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './ViewRecords.css';
import { useNavigate } from 'react-router-dom';
import editIcon from '../assets/editrecordwhite.png';
import copyWhite from '../assets/copywhite.png';
import { fetchAuthSession } from 'aws-amplify/auth'; 


const ViewRecords = () => {
  const [records, setRecords] = useState([]);
  const [editId, setEditId] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState({});
  const [editableRecord, setEditableRecord] = useState({
    first_name: '',
    last_name: '',
    organisation: '',
    title: '',
    email: '',
    address: '',
    url: '',
  });

  const navigate = useNavigate();
  const timeoutRefs = useRef({});


  useEffect(() => {
    const fetchRecords = async () => {
      try {
        const session = await fetchAuthSession();
        const accessToken = session.tokens.accessToken.toString();
        const response = await axios.get('https://api.srsnsw.com.au/contacts', {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });


        if (response.status === 200 || response.status === 201) {
          setRecords(response.data);
        } else {
          console.error('Error fetching contacts: Unexpected response status', response.status);
        }
      } catch (error) {
        console.error('Error fetching contacts:', error);
      }
    };

    fetchRecords();
    
    return () => Object.values(timeoutRefs.current).forEach(clearTimeout);
  }, []);


  const goToAddCard = () => {
    navigate('/add-contact');
  };

  const handleEditClick = (record) => {
    setEditId(record.id);
    setEditableRecord({ ...record });
  };

  const handleCancel = () => {
    setEditId(null);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditableRecord((prev) => ({
      ...prev,
      [name]: value,
    }));
  };


const handleSave = async () => {
  try {
    const session = await fetchAuthSession();
    const accessToken = session.tokens.accessToken.toString();

    const { id, ...updateData } = editableRecord; 
    await axios.put(`https://api.srsnsw.com.au/contacts/${editId}`, JSON.stringify(updateData), {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`
      }
    });

    const updatedRecords = records.map((rec) => 
      rec.id === editId ? { ...editableRecord, id: editId } : rec
    );
    setRecords(updatedRecords);

    setEditId(null);
  } catch (error) {
    console.error('Error updating contact:', error);
  }
};
 
  const handleDeleteConfirmation = (id) => {
    setConfirmDelete({ ...confirmDelete, [id]: true });
    timeoutRefs.current[id] = setTimeout(() => handleCancelDelete(id), 10000);
  };

  const handleCancelDelete = (id) => {
    const newConfirmDelete = { ...confirmDelete };
    delete newConfirmDelete[id];
    setConfirmDelete(newConfirmDelete);
    clearTimeout(timeoutRefs.current[id]);
  };


const handleDelete = async (id) => {
  clearTimeout(timeoutRefs.current[id]);
  try {
    const session = await fetchAuthSession();
    const accessToken = session.tokens.accessToken.toString();

    const response = await axios.delete(`https://api.srsnsw.com.au/contacts/${id}`, {
      headers: {
        'Authorization': `Bearer ${accessToken}`
      }
    });

    if (response.status === 200) {
      setRecords(records.filter(record => record.id !== id));
      handleCancelDelete(id); 
      alert("Record deleted successfully");
    } else {
      alert("Failed to delete the record.");
    }
  } catch (error) {
    console.error('Error deleting contact:', error);
  }
};


  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      alert('Link copied to clipboard');
    }, (err) => {
      console.error('Error coping link to clipboard: ', err);
    });
  };

return (
  <div className="records-main-container">
    <div className="topbar">
    <h1>View Records</h1>
        <button className="goAddCardButton" onClick={goToAddCard}>Go to Add Card</button>
    </div>

    {records.length > 0 ? (
      records.map((record) => (
        <div className="record-wrapper" key={record.id}>
          <div className="records-container"> { }
            {editId === record.id ? (
              
              <>
                <input type="text" name="first_name" value={editableRecord.first_name} onChange={handleInputChange} />
                <input type="text" name="last_name" value={editableRecord.last_name} onChange={handleInputChange} />
                <input type="text" name="organisation" value={editableRecord.organisation} onChange={handleInputChange} />
                <input type="text" name="title" value={editableRecord.title} onChange={handleInputChange} />
                <input type="email" name="email" value={editableRecord.email} onChange={handleInputChange} />
                <input type="text" name="address" value={editableRecord.address} onChange={handleInputChange} />
                <input type="url" name="url" value={editableRecord.url} onChange={handleInputChange} />
                <div className="editorbuttons">
                  <button className="edit-save" onClick={handleSave}>Save</button>
                  <button className="cancel-save" onClick={handleCancel}>Cancel</button>
                  <button 
                    onClick={() => confirmDelete[record.id] ? handleDelete(record.id) : handleDeleteConfirmation(record.id)}
                    className={confirmDelete[record.id] ? 'delete-button-confirm' : 'delete-button'}
                  >
                    {confirmDelete[record.id] ? 'Are you sure?' : 'Delete'}
                  </button>
                  {confirmDelete[record.id] && (
                    <button onClick={() => handleCancelDelete(record.id)} className="cancel-delete-button">Cancel</button>
                  )}

                </div>
              </>
            ) : (
              
              <>
                <p>First Name: {record.first_name}</p>
                <p>Last Name: {record.last_name}</p>
                <p>Organisation: {record.organisation}</p>
                <p>Title: {record.title}</p>
                <p>Email: {record.email}</p>
                <p>Address: {record.address}</p>
                <p>Website: {record.url}</p>
                <button className="copy-link-button"
                onClick={() => copyToClipboard(`api.srsnsw.com.au/contacts/${record.id}`)}
                >
                 <img src={copyWhite} alt="Copy" style={{width: '24px', height: '24px'}}/>
                </button>
              </>
            )}
          </div>
          {editId !== record.id && (
            <button onClick={() => handleEditClick(record)} className="edit-button">
              <img src={editIcon} alt="Edit" style={{width: '24px', height: '24px'}}/>
            </button>
          )}
        </div>
      ))
    ) : (
      <p>No records found.</p>
    )}
  </div>
);
};
export default ViewRecords;
